@import "nav";

.social-links{
  width: 100%;
  display: block;
  text-align: center;

  >a{
    & + a{
      margin-left: 10px;
    }
  }
}