@mixin fontsize($size:1.6, $line: $size * 1.5){
  font-size:   ($size * 10) + px;
  line-height: ($line * 10) + px;
  font-size:   $size + rem;
  line-height: $line + rem;
}

@mixin linkreset() {
  a.button{
    text-decoration: none;
  }
  a:not(.button){
    &, &:hover, &:active, &:visited{
      text-decoration: none;
      font-weight: inherit;
      font-size: inherit;
      color: inherit;        
    }
  }
}