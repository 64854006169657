@media print {
  * {
    background:transparent !important;
    color:#000 !important;
    text-shadow:none !important;
    filter:none !important;
    -ms-filter:none !important;
  }
  
  body {
    margin:0;
    padding:0;
    line-height: 1.4em;
  }

  footer, .header, .footer, .top-bar, .social-share-float, form, .content-area > section > *:not(.container){
    display: none;
  }
}