@import "bootstrap",
	"container",
	"sections",
	"grid"; 


// ----------- Body -----------
body{
	&.no-scroll{
		overflow: hidden!important;
		@at-root{
			html.no-scroll{
				overflow: hidden!important;
			}
		}
	}
}

.content-init{
	padding-top: 180px;

	&.large{
		padding-top: 280px;
	}
}