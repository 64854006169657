@import "settings/all"; // Configurações do SASS - Variáveis, Mixins, Funções, etc
/* -----------------------------------*/

/* ---------->>> GLOBAL <<<-----------*/

/* -----------------------------------*/

@import "base/resets/all", // Normalize, reset browser style, etc
"base/layout/all", // Grid, containers, etc
"base/typography/all" // Formatação de textos, títulos e etc
;

/* ---------------------------------------------------*/
/* ---------->>> COMPONENTES PRIMÁRIOS <<<-----------*/
/* ---------------------------------------------------*/
@import './../components/header/all';
@import './../components/nav/all';
@import './../components/overlay/all';
@import './../components/modal/all';
@import './../components/buttons/all'; 
@import './../components/footer/all';
@import "utils/all"; // Classes auxiliares para facilitar o desenvolvimento