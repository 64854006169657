@mixin absolute_all() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin absolute_centered() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute_vcentered() {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@mixin absolute_hcentered() {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}