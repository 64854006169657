// Configurações de Cores

// As tonalidades das Cores serão definidas por:
// ice, clean, light, dark, heavy

// based page root css var

$palettes: (
    grey: (
        black: #000,
        heavy: #222,
        dark: #666,
        base: #888,
        light: #c5cad0,
        clean: #eee, 
        ice: #f5f5f5,
        white: #fff,
    ), 
    brand: ( 
        primary: var(--color-primary), //Principal 
        secondary: var(--color-secondary),//Alternativa
        accent: var(--color-accent), //Destaque com a cor principal
        contrast: var(--color-contrast), //Contraste com a cor principal
        highlight: var(--color-highlight), //Destaque, 
    ),
    text: ( 
        primary: var(--color-primary),
        primary-contrast: var(--color-text-contrast),
        default: var(--color-text),
        light: var(--color-text),
    ),
    border: (
        default: #eee,
        light: #d6e7f0,
        clean: #eee,
        dark: #222,
        warning: #A3921E
    ),
    stage: (
        success: #3ed22c,
        danger: #c82d2d,
        alert: #d29f2c,
        warning: #d2522c,
        info: #2cbbd2,
    ),
    outer: (
        facebook: #3C579D
    ),
    alert: (
        warning:#F5F4CE,
    )
);

$white: #FFF;
$black: #000;

// Funções de Cores
$bg-body: #f5f5f5; 
