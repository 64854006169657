.container {
	max-width: 1170px;
	width: 100%; 
	padding-left: 15px; 
	padding-right: 15px;
	margin: 0 auto;

	@include min-screen($start-mobile) {
		padding: {
			right: 15px;
			left: 15px; 
		}
	}


	&.small{ 
		max-width: 610px;
	}

	&.medium{
		max-width: 880px;
	}
 
	&.large{ 
		max-width: 1800px;
	}

	@include clearfix(); 
}