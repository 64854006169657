$color-primary: palette(brand, primary);
$color-primary-light: palette(brand, primary-light);
$text-color: palette(text, default);
$text-color-light: palette(text, light);
.nav {
  width: 100%;
  align-items: center;
  display: flex;
  ul,
  li {
    list-style: none;
  }
  >a {
    display: inline-block;
    vertical-align: middle;
  }
  $nav_space: 20px;
  $nav_color: palette(text);
  >ul,
  .menu {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 -#{$nav_space};
    @include max-screen(1100px) {
      margin: 0px -10px;
    }
    @include max-screen(1400px) {
      margin: 0px -15px;
    }
    >li {
      display: block;
      padding: 10px 0;
      font-family: $font-primary;
      color: $nav_color;
      font-size: 16px;
      text-transform: none;
      flex: 0 0 auto;
      width: auto;
      font-weight: 400;
      transition: all 0.3s ease;
      >a {
        padding: 0 $nav_space;
        &.compact {
          padding: 0px;
        }
        @include max-screen(1100px) {
          padding: 0 10px;
        }
        @include max-screen(1400px) {
          padding: 0 15px;
        }
      }
      a,
      span {
        >i,
        >svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      &:after {
        content: "";
        width: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
        height: 3px;
        background: currentColor;
        display: block;
      }
      /*----------  HOVER  ----------*/
      &:hover {
        color: palette(brand, primary);
        &:after {
          width: 100%;
        }
      }
    }
    @include clearfix();
  } // global
  li {
    position: relative;
    >a,
    >span {
      display: block;
    }
    @include linkreset();
    &.menu-item-has-children,
    &.has-children {
      &:hover {
        >.sub-menu {
          visibility: visible;
        }
      }
      >span,
      >a {
        padding-right: 15px;
        &:after {
          @include materialIcons('arrow_drop_down');
          font-size: 20px;
          color: inherit;
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
        }
      }
    }
  }
  ul.sub-menu {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    left: -4px;
    background: #fff;
    top: calc(100% - 5px);
    width: 220px;
    box-shadow: rgba(#000, 0.2) 0 2px 10px;
    border-radius: 0px;
    &:hover {
      display: block;
      visibility: visible;
    }
    >li {
      &:first-child {
        padding-top: 8px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
    }
    li {
      display: block;
      background: #FFF;
      color: palette(text, default);
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      width: 100%;
      position: relative;
      overflow: initial;
      &:before {
        content: "";
        display: block;
        @include absolute_all();
        background: palette(brand, dark);
        transform: scale(0);
        opacity: 0;
        transition: all 0.5s;
      }
      >a,
      >span {
        padding: 12px 15px;
        display: block;
      }
      a {
        position: relative;
        z-index: 2;
      }
      &.menu-item-has-children,
      &.has-children {
        >a {
          padding-right: 15px;
          display: block;
          position: relative;
          z-index: 2;
          img,
          .svg,
          i {
            width: 28px;
            height: 28px;
            font-size: 26px;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
          }
          &:after {
            @include materialIcons('chevron_right');
            font-size: 20px;
            color: inherit;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
        }
        >.sub-menu {
          left: 220px;
          top: 0;
          z-index: -1;
        }
      }
      &:hover {
        color: palette(brand, primary);
        &:before {
          transform: scale(1);
          opacity: 1;
        }
        >a {
          &:after {
            color: palette(brand, primary)
          }
        }
      }
    }
    &.right {
      left: initial !important;
      right: 0 !important;
      .sub-menu {
        z-index: 3 !important;
        top: 100% !important;
        box-shadow: rgba(#000, 0.3) 0 0 4px;
        right: 220px !important;
      }
    }
  } //.sub-menu
  /*----------  Mutations  ----------*/
  &.right {
    &,
    .menu,
    >ul {
      justify-content: flex-end;
    }
  } //modifier
  &.center {
    &,
    .menu {
      justify-content: center;
    }
  } //modifier
  &.equal {
    &,
    .menu {
      justify-content: space-between;
      >ul {
        display: flex;
        width: 100%;
        justify-content: space-between;
        >li {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          width: 100%;
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  } //modifier
  &.fixed {
    position: fixed;
    width: 100%;
    z-index: 50;
    @include m('left') {
      position: fixed;
      left: 8%;
      top: 50%;
      transform: translateY(-60%);
      z-index: 3;
      a {
        display: block;
        padding: 5px 0;
        text-align: left;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        color: palette(text, light);
        &:after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 2px;
          background: palette(brand, primary);
          margin-left: 10px;
          transition: all 0.5s;
        }
        &:hover,
        &.active {
          color: palette(brand, primary);
          &:after {
            width: 50px;
          }
        }
      }
      @include max-screen($start-mobile - 1px) {
        display: none;
      }
    }
    @include m('bottom') {
      position: fixed;
      width: 100%;
      z-index: 50;
      bottom: 0;
      left: 0;
      @include min-screen($start-mobile) {
        display: none;
      }
    } //modifier
  } //modifier
  &.vertical {
    position: relative;
    display: block;
    >ul,
    .menu {
      display: block;
      margin: 0 0;
      li {
        display: block;
        width: 100%;
        padding: 8px 0px;
        margin: 0 0;
        color: palette(text, default);
        font-size: 17px;
        font-size: 16px;
        text-transform: none;
        font-weight: 500;
        i,
        .icon,
        svg {
          margin-right: 10px;
        }
        a {
          display: block;
          >* {
            display: inline-block;
          }
        }
        &:hover {
          color: palette(brand, primary);
          background: transparent;
        }
        &.menu-item-has-children {
          >a {
            padding-right: 30px;
            position: relative;
            display: block;
            &:after {
              @include materialIcons("chevron_right");
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              font-size: 18px;
              right: 10px;
              color: $text-color-light;
            }
          }
        }
        ul:not(.sub-menu-active) {
          display: none;
          animation: MenuAnimOut 0.4s linear forwards;
        }
        .sub-menu,
        .submenu {
          box-shadow: none;
          position: relative;
          top: 0;
          left: 0;
          margin: 0 15px 0 0;
          padding: 0;
          width: 100%;
          max-width: 100%;
          >li {
            font-size: 80%;
            &:after {
              content: none;
            }
            >a {
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }
        }
        @include linkreset();
      } //li
      &.sub-menu-active,
      .sub-menu-active {
        >li {
          &:not(.active) {
            display: none;
          }
          .return {
            background: rgba(#000, 0.4);
            border-bottom: 0;
            a {
              background: transparent;
              opacity: 0.6;
              i {
                margin-right: 10px;
                font-size: 20px;
              }
            }
          }
          &.active {
            border-bottom: 0;
            padding-top: 0;
            >a {
              display: none;
            }
            >ul {
              display: block;
              animation: MenuAnimIn 0.3s linear forwards;
            }
          }
        }
      }
    } //>ul, .menu
  } //modifier 
  &.icons {
    margin: 25px auto;
    ul {
      justify-content: center;
      margin-left: -3px;
      margin-right: -3px;
    }
    li {
      color: #FFF;
      font-size: 16px;
      padding: 5px 3px;
      margin: 0;
      @include linkreset();
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: palette(brand, primary);
        transition: all 0.5s;
      }
      &:hover {
        color: #FFF;
        a {
          background: palette(brand, accent);
          color: #FFF;
        }
      }
    }
  }
  &.boxes {
    margin-bottom: 30px;
    ul {
      display: flex;
      align-items: initial;
    }
    li {
      border: 1px rgba(#FFF, 0.4) solid;
      color: rgba(#FFF, 0.5);
      font-family: $font-primary;
      text-transform: none;
      font-size: 14px;
      padding: 15px 50px 15px 25px;
      transition: 0.4s;
      img {
        opacity: 0.4;
        transition: 0.4s;
      }
      &.active,
      &:hover,
      &.current-menu-item {
        color: #FFF;
        img {
          opacity: 1;
        }
      }
      &.active,
      &.current-menu-item {
        &:after {
          content: "";
          height: 3px;
          width: 100%;
          display: block;
          background: #fff;
          position: absolute;
          bottom: -8px;
          left: 0;
        }
      }
    }
  }
  &.dividers {
    &>li,
    &>ul>li {
      >a {
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 90%;
          background: currentColor;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:last-child {
        >a {
          &:after {
            content: none;
          }
        }
      }
    }
  }
} //.nav

.social-links{

  &.round{
    padding: 10px 0 0;
    display: flex;
    justify-content: center;


    a{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: palette(brand, primary);
      background: var(--color-primary);
      color: #FFF!important;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      >*{
        color: inherit;
        margin: 0!important;
      }
    }
  }

  a{
    transition: transform 0.3s;
    transform: rotate(0);
    display: inline-block;
    will-change: transform;
    text-decoration: none!important;
    &:hover{
      transform: rotate(360deg);
    }
  }
}