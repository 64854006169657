.top-bar {
  padding: 10px 0;
  background-color: var(--color-primary);
  color: #FFF;
  @include linkreset();
  a:hover {
    opacity: 0.7;
  }
  i {
    display: inline-block;
    vertical-align: middle;
  }
  i+span {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .end {
    text-align: right;
    >.row {
      justify-content: flex-end;
    }
  }
  /*============= MOBILE =============*/
  @include max-screen (1024px){
    display: none;
  }
}