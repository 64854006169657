.button{
  cursor: pointer;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border: none;
  vertical-align: middle;
  background-color: none;
  font-family: $btn-ff;
  margin: 0;  
  padding: 0.8em 1em 0.8em 1em;
  text-transform: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1;  
  font-style: normal;
  font-size: $btn-fz;
  text-align: center;
  text-decoration: none;
  border-radius: $btn-bd-radius;
  box-shadow: none;
  user-select: none;
  cursor: pointer; 
  position: relative;
  transition: all 0.5s ease;

  // icons 
  i, svg, img, span{
    display: inline-block;
    vertical-align: middle;
    transition: all 0.4s ease;
  }

  .badge{
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .label{
    
  }

  i, svg, img{
    &:first-child:not(:last-child){
      margin-right: 7px;
    }
    &:last-child:not(:first-child){ 
      margin-left: 7px;
    } 
  }

  svg{
    width: 22px;
    height: 22px;
  }

  i{
    font-size: 1.2em;
  }

  &:focus, &:active{
    
  }

  &:hover{
    
  } 

  &:disabled{
    opacity: 0.3;
    cursor: default;
  }

  /*----------  Mode  ----------*/
  &.compact{
    padding: 0.58928571em 1.125em 0.58928571em;
  }

  &.icon{
    padding: 0;
    position: relative;
    min-height: initial;
    width: 50px;
    height: 50px;
    font-size: 24px;
    
    >*{
      @include absolute_centered();
    }

    i, span{
      font-size: inherit;
      transition: transform 0.3s, opacity 0.3s;
    }

    i{
      &:before{
        transition: transform 0.3s, opacity 0.3s;
        transform: rotate(0deg);
      }
    }

    &.small{
      width: 30px;
      height: 30px;
      font-size: 18px;
    }

    &.medium{
      width: 60px;
      height: 60px;
      font-size: 36px;
    }

    &.large{
      width: 100px;
      height: 100px;
      font-size: 60px;
    }

    &:hover{
      i{
        &:before{
          transform: rotate(360deg);
        }
      }
    }
  }

  &.block{
    width: 100%;

    & + .button{
      margin: 10px 0 0 0;
    }
  }

  &.rounded{
    border-radius: 10px;
  }

  &.round{
    border-radius: 50px;
  }
  
  &.square{
    border-radius: 0px;
  }

  &.circle{
    border-radius: 50%;
  }

  /*----------  Colors  ----------*/
  @each $color-name, $color-code in $btncolors {
    &.#{$color-name} {
      background-color: transparent;
      color: $color-code;
      fill: $color-code;

      &:hover{ 
        background-color: #f5f5f5;
      }
    }
  }

  &.clean{
    background-color: rgba(#fff, 0.5);
    color: palette(brand, primary); 
    border: solid 1px transparent;
    
    &.border{
      border: solid 1px #FFF;      
    }
  }

  &.icon-helper{
    text-align: center;
    padding: 5px;
    color: palette(brand, text);

    i, span{
      display: block;
      margin-left: 0!important;
      margin-right: 0!important;
    }

    i{
      font-size: 30px;

      &.small{
        font-size: 26px;
      }
    }
    span{
      font-size: 12px;
      font-family: $font-primary;
      text-transform: lowercase;

    }
  }

  /*----------  Sizes  ----------*/
  @each $size-name, $size-code in $btnsizes {
    &.#{$size-name} {
      font-size: $size-code;
    }
  }
 
}

.toggle-visibility{
  width: 30px;
  height: 32px;
  position: relative;
  display: inline-block;
  border: 1px solid palette(brand, primary);
  border-radius: 5px;

  span{
    &:before, &:after{
      content: "";
      display: block;
      height: 2px;
      width: 70%;
      background-color: palette(brand, primary);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after{
      opacity: 0;
      transition: all 0.5s;
    }
  }
  
  &.active{
    span{
      &:after{
        transform: translate(-50%, -50%) rotate(90deg);
        opacity: 1;
      }
    }
  }

  &:hover{
    border: 1px solid palette(brand, primary-light);

    span{
      &:before, &:after{
        background-color: palette(brand, primary-light);
      }
    }
  }
}

// bugs
*.button{
  *{
    cursor: pointer;
  }
}

.button{
  &:focus, &:active, &:hover{
    text-decoration: none!important;
    outline: none!important;
  }
} 

