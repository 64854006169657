.button-hamburger{
  position: relative;
  padding: 0;

  small{
    text-transform: uppercase;
    font-size: 30px;
    position: absolute;
    left: 0px;
    top: calc(100% - 7px);
    width: 100%;
    display: block;
    text-align: center;
    color: palette(brand, contrast);
  }

  @mixin icon($height, $width) {
    transition-duration: 0.5s;
    position: absolute;
    height: $height;
    width: $width;
    top: 30px;
    background-color: palette(brand, contrast);
    display: block;
  }

  @mixin icon-before($height, $width, $top) {
    transition-duration: 0.5s;
    position: absolute;
    width: $width;
    height: $height;
    background-color: palette(brand, contrast);
    content: ""; 
    top: $top;
  }

  @mixin icon-after($height, $width, $top) {
    transition-duration: 0.5s;
    position: absolute;
    width: $width;
    height: $height;
    background-color: palette(brand, contrast);
    content: "";
    top: $top;
  } 

  &{
    width: 40px; 
    height: 36px;
    transition-duration: 0.5s;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    position: relative;
    cursor: pointer;
    display: block;
    
    .icon-left {
      @include icon(5px, 20px);
      top: 14px;
      left: 0;      
      border-radius: 30px 0px 0px 30px; 

      &:before{
        @include icon-before(5px, 20px, -14px);
        left: 0;
        border-radius: 30px 0px 0px 30px; 
      }
      
      &:after{
        @include icon-after(5px, 20px, 14px);
        left: 0;
        border-radius: 30px 0px 0px 30px; 
      }
      
      &:hover{
        
      }
    }
    
    .icon-right {
      @include icon(5px, 20px);
      top: 14px;
      left: 20px;
      border-radius: 0 30px 30px 0; 
      
      &:before{
        @include icon-before(5px, 20px, -14px);
        left: 0;
        border-radius: 0 30px 30px 0; 
      }
      
      &:after{
        @include icon-after(5px, 20px, 14px);
        left: 0;
        border-radius: 0 30px 30px 0; 
      }
    }
    
    &.open, &.active {

      small{
        opacity: 0;
      }

      .icon-left {
        transition-duration: 0.5s;
        background: transparent;
        
        &:before{
          transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
        }

        &:after{
          transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
        }
      }
      
      .icon-right {
        transition-duration: 0.5s;
        background: transparent;  
        
        &:before{
          transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 3px);
        }

        &:after{
          transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -3px);
        }
      }
    }
  }
}