.button.outlined{  
  border-width: 2px;
  border-style: solid;

  &:hover{
  }

  /*----------  Colors  ----------*/
  @each $color-name, $color-code in $btncolors {
    &.#{$color-name} {
      border-color: $color-code; 
      background-color: transparent; 
      color: $color-code;
      fill: $color-code;
 
      &:hover{ 
        background-color: $color-code;
        color: #FFF;
        fill: #FFF;
      }
    }
  }

  /*----------  Custom  ----------*/
  &.white{
    border-color: #fff;
    color: #fff;
    fill: #fff;

    &:hover{
      background-color: #fff;
      color: palette(brand, primary);
      fill: palette(brand, primary);
    }
  }
}