.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog {
  transform: translate(0);
}

.modal-backdrop {
  display: none !important;
}

.modal {
  text-align: center;
  padding: 0 !important;
  background: rgba(#000, 0.5);
  display: block !important;
  transform: translateY(-150%);
  transition: 0s;
  &.active,
  &.show,
  &.in {
    transform: translateY(0);
  }
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
    @include max-screen(1023px) {
      display: none;
    }
  }
  .close {
    opacity: 1;
    font-size: em(14);
    text-transform: uppercase;
    >.svg {
      width: 12px;
      height: 12px;
      display: inline-block;
      &:first-child {
        margin: 0 5px;
      }
    }
  }
  .modal-dialog {
    max-width: 700px;
    width: 100%;
    z-index: 3;
  }
  .modal-body {
    padding: 25px;
    >* {
      position: relative;
      z-index: 2;
    }
    >.bg-cover {
      z-index: 1;
      position: absolute;
    }
    >.close {
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: palette(text, light);
    }
  }
  .modal-header {
    padding: 20px;
    background: palette(brand, primary);
    color: palette(brand, accent);
    .close {
      fill: #fff;
      color: #fff;
    }
    .modal-title {
      color: inherit;
      font-weight: 400;
      font-size: 24px;
    }
  }
  .modal-header,
  .modal-footer {
    border: 0;
    position: relative;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
  .modal-content {
    border: 0;
  } // centered
  &.modal--centered {
    &.large {
      .modal-footer {
        .close.centered {
          margin: 0 auto;
          text-align: center;
          display: block;
          width: 100%;
          transform: translateY(30px);
          .circle {
            display: block;
            margin: 5px auto;
            transform: translateY(10px);
          }
        }
      }
      .modal-dialog {
        padding-left: 8%;
        padding-right: 8%;
        max-width: 100%;
        width: 100%;
      }
      .modal-body {
        width: 100%;
        @include min-screen("1000px") {
          &[data-simplebar] {
            height: calc(86vh - 140px);
            overflow-y: auto;
            overflow-x: hidden;
            .simplebar-scroll-content {
              overflow-y: auto;
            }
            .simplebar-track.horizontal {
              display: none;
            }
          }
        }
        @include min-screen("1200px") {
          padding: 18px 28px;
        }
      }
    }
  } //&.centered
  &.notify {
    .modal-dialog {
      max-width: 530px;
    }
    .close {
      text-transform: uppercase;
      font-size: 12px;
      color: palette(brand, secondary);
      cursor: pointer;
      display: table;
      position: absolute;
      top: 15px;
      right: 10px;
      z-index: 10;
      i {
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
        transform: translateY(-2px);
      }
    }
    .modal-content {
      padding: 50px 25px;
      text-align: center;
      color: palette(brand, primary);
      .icon {
        font-size: 70px;
        color: palette(brand, primary);
        fill: palette(brand, primary);
        margin-bottom: 20px;
        .svg {
          width: 70px;
          height: 70px;
        }
      }
      .text {
        font-size: 18px;
        color: palette(brand, primary);
        * {
          margin: 0 0;
          &+* {
            margin-top: 10px;
          }
        }
        h3,
        h2 {
          font-size: em(32);
          font-weight: 200;
        }
      }
    }
  } //.notify
} //.modal
#modal-login,
#modal-register,
#modal-recover-password {
  .modal-dialog {
    width: 400px;
    max-width: calc(100vw - 20px);
  }
}

// Custom notify
[data-notify="container"] {
  z-index: 2000 !important;
  max-width: calc(100vw - 40px);
  width: auto;
}