@mixin selection($current-selector: false) {

  @if $current-selector {
    &::-moz-selection {
      @content;
    }

    &::selection {
      @content;
    }
  } @else {
    ::-moz-selection {
      @content;
    }

    ::selection {
      @content;
    }
  }
} 