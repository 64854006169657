/*===============================
=            OVERLAY            =
===============================*/
$overlayGutter: 1.5rem;

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: transparent;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0s 0.6s;
  display: block;
  overflow: hidden;
  color: palette(text);
  
  &__content {
    padding: 0;
    position: absolute;
    left: initial;
    right: 0;
    z-index: 5;
    width: 100%;
    min-height: 100%;
    background: #fff;
    overflow: hidden;
    transform: translateX(120%);
    transition: all 0.6s;

    > .button.close{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 55px;
      color: #FFF;
      text-shadow: #000000a8 1px 0 3px;
      z-index: 10;
    }
  }
  
  @include e('body') { 
    position: relative;
    padding: $overlayGutter $overlayGutter 0px; 
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;

    @include max-screen(400px) {
      padding: $overlayGutter 15px 0px;
    }

    >*:last-child:not(.overlay__scroll){
      margin-bottom: 40px;
    }
    
    .overlay__scroll{
      padding-bottom: 40px;
    }
 
    >.close{
      width: 40px;
      height: 40px;
      background: palette(brand, primary);
      color: #FFF;
      fill: #FFF;
    }

    .overlay__title{
      font-family: $font-secondary;
      font-size: em(22);
      color: pallet(brand, primary);
      margin: 15px 0 10px;
      text-transform: none;
      text-align: center;
      padding-top: 14px;
    }

  }

  &__scroll{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    margin-right: -3rem;
    padding-right: 3rem;
    -webkit-overflow-scrolling: touch;

    >*{
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  @include e('title') {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }

  @include e('header') {
    padding: 5px 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid palette(border);

    &.fixed{
      padding-left: 0;
      padding-right: 0;
      display: block;

      .col{
        padding-left: 0;
        padding-right: 0;
      }

      .col-auto{
        min-width: 79px;
      }

      .button{
        font-size: 24px;
        padding: 5px 7px;
        color: palette(text);

        &:hover, &:focus{
          color: currentColor;
        }
      }

      .overlay__title{
        font-family: $font-primary;
        font-size: em(18);
        color: pallet(brand, primary);
        margin: 0;
        text-transform: none;
        text-align: center;
        padding-top: 14px;
      }

    }

    button{
      i{
        font-size: 28px;
      }
    }

    .overlay-search{
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);
      opacity: 0;
      transition: transform 0.5s;
      background: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 40px; 

      .button{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.active{
        transform: translateY(0);
        opacity: 1;
      }

      input{
        border: 0;
        padding: 10px; 
      }
    }

    .svg, img, .button, a, i{
      &:first-child:not(:last-child){
        margin-right: 10px;
      }
      &:last-child:not(:first-child){
        margin-left: 10px;
      }
    }

    .button.icon{
      &:first-child{
        margin-left: -5px;
      }
    }

    .close{
       
    }

    @include e('content') {
      padding: 14px 15px;
      position: relative;
    }

    @include linkreset();
  }
  

  /*----------  Mutations  ----------*/
  &.right{
    transform: translateX(100%);
    
    .overlay__content{
      left: 0;
      right: initial;
      transform: translateX(-120%);
    }
  }

  &.up{
    top: initial;
    bottom: 0;
    transform: translateY(100%);

    .overlay__content{
      transform: translateY(120%);
    }

    &.active{
      transform: translateY(0);

      .overlay__content{
        transform: translateY(0);
      }
    }
  }

  &.down{
    top: initial;
    bottom: 0;
    transform: translateY(-100%);

    .overlay__content{
      transform: translateY(-120%);
    }

    &.active{
      transform: translateY(0);

      .overlay__content{
        transform: translateY(0);
      }
    }
  }
  
  &.zoom{

    .overlay__content{
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      border-radius: 50%;
      opacity: 0;
    }
    
    &.active{
      
      .overlay__content{
        opacity: 1;
        border-radius: 0;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &.side{
    .overlay__content{
      width: 80vw;
      max-width: 300px;
    }
  }

  &.semi{
    .overlay__content{
      position: absolute;
      top: initial;
      bottom: 0;
      width: 100%;
      min-height: 100vh;
      
      @media screen and (min-height: 500px) {
        min-height: 76vh;
        max-height: 76vh;  
      }
    }
    @media screen and (min-height: 500px) {
      .overlay__body{
        height: calc(76vh - 60px);
      }
    }
  }

  // Em desktop elas viram modais
  @include min-screen(1024px) {
    &.up, &.down, &.semi {
      transform: translateY(100%);
      opacity: 1;
      transition: all 0.5s 0s;

      .mask{
        opacity: 0;
        transform: scale(0) translate(-50%, -50%);
        transition: all 0.5s;
        border-radius: 50%;
        width: 1000px;
        height: 1000px;
        top: 50%;
        left: 50%;
      }

      .overlay__content {
        top: 150%;
        left: 50%;
        bottom: initial;
        right: initial;
        transform: translate(-50%, -50%);
        max-width: 900px;
        opacity: 0;
        transition: all 0.5s 0s;
      }
      
      .overlay__content,
      .overlay__body{
        height: auto;
        min-height: initial;
        max-height: initial;
      }

      .overlay__body{
        >:last-child:not(.overlay__scroll){
          margin-bottom: 0;
        }

        form{
          max-width: 400px;
          margin: 0 auto;
        }
      }

      &.active{
        transform: translateY(0);
        opacity: 1;
        transition: all 0.5s 0s;  

        .mask{
          opacity: 1;
          transform: scale(1) translate(-50%, -50%);
          width: 100%;
          height: 100%;
          border-radius: 0;
          transition: all 1s 0s;
        }

        .overlay__content{
          top: 50%;
          opacity: 1;
          transition: all 1s 0.5s;
        }
        
        
      }
    }
  }
  
  /*----------  States  ----------*/
  &.active{
    transform: translateX(0);
    opacity: 1;
    transition: all 0s 0s;

    .mask{
      opacity: 1;
      transform: none;
    }

    .overlay__content{
      transform: translateX(0);
    }
  }

  /*----------  Themes  ----------*/
  $overlayColors: (
    "primary": palette(brand, primary),
    "secondary": palette(brand, secondary),
    "contrast": palette(brand, contrast),
    "accent": palette(brand, accent),
    "highlight": palette(brand, highlight),
    "orange": #ff8f21,
    "red": #f25138,
    "magenta": #d02d5c,
    "blue": #0a4c8c
  );
  @each $color-name, $color-code in $overlayColors {
    &.#{$color-name} {
      color: $color-code;

      .tab__nav li.active:before{
        background: $color-code;
      }
    }
  }

  /*----------  Custom  ----------*/
  &__body{
    .simplebar-track.horizontal{display: none;}
    .simplebar-scroll-content{
      padding-right: 50px!important;
    }
    &[data-simplebar=init]{
      padding: 0;
    }
    .simplebar-content{
      overflow-x: hidden;
      padding: #{$overlayGutter / 1.8} $overlayGutter 40px;
    }

    p, li{
      line-height: 1.3;
    }
  }

  .nav{
    position: relative;

    &:before{
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -#{$overlayGutter};
      background: palette(brand, primary);
    }

    i, svg{
      color: palette(brand, primary);
      font-size: em(22);
      transition: all .3s;
    }

    >ul{
      >li{
        font-size: em(18);

        >a{
          display: flex;
          align-items: flex-start;
          width: 100%;
        }

        span{
          display: block;
          width: 100%;
          text-align: center;
          padding-top: 4px;
          &:after{
            content: "";
            width: 70px;
            height: 1px;
            background: palette(brand, primary);
            display: block;
            margin: 20px auto 6px;
            transition: all .3s;
          }
          
        }

        &:hover,
        &:not(:first-child) a.active {
          color: palette(brand, secondary); 

          i{
            transform: rotate(360deg); 
          }

          span{
            &:after{
              width: 120px;
            }
          }
        }

      }
    }
  }

  .icon-feature{
    font-size: 60px;
    color: currentColor;
    margin-bottom: 15px;
  }

  .tab{

    &__body{
      padding: 30px 0;

      .simplebar-content{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  form{
    [type=submit]{
      font-size: 14px;
      padding: 18px 15px 15px;
      text-transform: uppercase;
      display: block;
      width: 100%;

      &:hover{
        background: palette(brand, primary);
      }
    }

    .form__title{
      display: none;
    }
  }

  ul.contact-list {
    background: #f5f5f5;
    padding: 15px;
    margin: -1.5rem -15px 30px;
  }

  .search-form.inline{
    margin-top: -15px;
    margin-bottom: 25px;
    input{
      border: 0;
      border-bottom: 2px solid palette(border);
      border-radius: 0;
    }
  }

 
}
/*=====  End of OVERLAY  ======*/

#overlay-contact{
  .overlay__content{
    max-width: 460px;
  }
}



#overlay-search .overlay__content {
  background: rgba(255, 255, 255, 0.6);

  h2{
    font-weight: bold;
    font-size: 50px;
    font-family: $font-secondary;
    color: palette(brand, primary);

    @include max-screen(900px){
      font-size: 30px;
    }
  }
}

div.mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(#000, 0.2);
  opacity: 0;
  transition: opacity 0.3s;
  transform: translateY(200%);

  &.active, .active > &{
    opacity: 1;
    transform: translateY(0);
  }
}

.active div.mask{
  opacity: 1;
  transform: translateY(0);
}