@mixin clearfix() {
    .ie7 & { zoom:1 }

    &:before,
    &:after {
        content:"";
        display: table; 
    }
    &:after {
        clear:both;
    }
}