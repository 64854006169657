@mixin fonticon($content: '\f005') {
	content: $content;
	font: normal normal normal 2em/1 FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;    
}
 
@mixin flaticon($content) {
	content: $content;
	font: normal normal normal 2em/1 Flaticon;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;    
}

@mixin icomoon($content) {
	content: $content;
	font: normal normal normal 2em/1 icomoon;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;    
}

@mixin materialIcons($content) {
	content: $content;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal; 
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}