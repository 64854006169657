/* =============== Arrow Down =============== */
$baseArrowDown: 0.6rem;
$colorArrowDown: #FFF;
.arrow-down {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 auto;

  .chevron {
    position: absolute;
    width: $baseArrowDown * 2.5;
    height: $baseArrowDown * 0.5;
    opacity: 0;
    transform: scale(0.3);
    animation: arrow-down 3s ease-out infinite;
  }
  
  .chevron:first-child {
    animation: arrow-down 3s ease-out 1s infinite;
  }
  
  .chevron:nth-child(2) {
    animation: arrow-down 3s ease-out 2s infinite;
  }
  
  .chevron:before,
  .chevron:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: $colorArrowDown;
  }
  
  .chevron:before {
    left: 0;
    transform: skewY(30deg);
  }
  
  .chevron:after {
    right: 0;
    width: 50%;
    transform: skewY(-30deg);
  }
}

@keyframes arrow-down {
 25% {
  opacity: 1;
	}
 33.3% {
  opacity: 1;
  transform: translateY($baseArrowDown * 2.8);
 }
 66.6% {
  opacity: 1;
  transform: translateY($baseArrowDown * 4.2);
 }
 100% {
  opacity: 0;
  transform: translateY($baseArrowDown * 6) scale(0.5);
 }
}
/* =============== / Arrow Down =============== */