// Reset
button, input[type=submit], input[type=reset], input[type=button]{
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  font-size: inherit;
  background-color:transparent;
  cursor: pointer; 

  &:focus, &:active, &:hover{
    border: 0;
    outline: none!important;
    box-shadow: none;
  }

  &:disabled{
    opacity: 0.3;
    cursor: default;
  }

  *{
    cursor: inherit;
  }
}

/*
 *
 * Buttons Variables
 *
*/
// *Sizes
$btn-fz: 16px;
$btn-fz-xsmall: 10px;
$btn-fz-small: 12px;
$btn-fz-tiny: 13px;
$btn-fz-medium: 16px;
$btn-fz-large: 20px;
$btn-fz-xlarge: 24px;
$btn-fz-xxlarge: 28px;
$btn-fz-big: 32px;
$btn-fz-huge: 38px; 

$btnsizes: (
  "default": $btn-fz,
  "xsmall": $btn-fz-xsmall,
  "small": $btn-fz-small,
  "tiny": $btn-fz-tiny,
  "medium": $btn-fz-medium,
  "large": $btn-fz-large,
  "xlarge": $btn-fz-xlarge,
  "xxlarge": $btn-fz-xxlarge,
  "big": $btn-fz-big,
  "huge": $btn-fz-huge,
);

$btn-ff: $font-primary;

$btn-bd: 0px;
$btn-bd-radius: 0px;

$btncolors: (
  "primary": palette(brand, primary),
  "secondary": palette(brand, secondary),
  "contrast": palette(brand, contrast),
  "accent": palette(brand, accent),
  "highlight": palette(brand, highlight),
  "success": palette(stage, success),
  "danger": palette(stage, danger),
  "info": palette(stage, info),
  "warning": palette(stage, warning),
  "facebook": $color-facebook,
  "google-plus": $color-google-plus,
  "twitter": $color-twitter,
  "email": #999,
  "whatsapp": $color-whatsapp,
  "default": #999
);

// Imports
@import "buttons";
@import "outlined";
@import "flat";
@import "layer";
@import "animated";
@import "badges";
@import "hamburguer";

/*----------  Custom bootstrap  ----------*/
.btn{
  &.btn-primary{
    background-color: palette(brand, primary)!important;
    border: 0;
  }
}

/*----------  Toggle Buttons  ----------*/
.toggle-buttons{
  display: inline-block;
  vertical-align: middle;

  .button{
    &:first-child{
      display: none!important;
    }
  }

  &.active{
    .button{
      &:first-child{
        display: block!important;
      }
      &:last-child{
        display: none!important;
      }
    }
  }
}
