/*----------  Colors Class  ----------*/
$colors: (
  "primary": palette(brand, primary),
  "secondary": palette(brand, secondary),
  "contrast": palette(brand, contrast),
  "accent": palette(brand, accent),
  "hightlight": palette(brand, hightlight),
  "grey-light": #f5f5f5, 
  "white": #FFF,
  "default": palette(text),
);

@each $color-name, $color-code in $colors {
  .text-#{$color-name} {
    color: $color-code!important; 
  }
  .fill-#{$color-name} {
    fill: $color-code!important;
  }
  .bg-#{$color-name} { 
    background-color: $color-code!important;
    color: #fff;
  }
}

// Backgrounds
.bg-yellow {
  background-image: radial-gradient(ellipse at center, #ffaa21 0%, #ffaa21 25%, #ff8f21 60%, #ff8f21 100%);
  color: #fff;
}

.bg-orange {
  background-image: radial-gradient(ellipse at center, #f28538 0%, #f28538 30%, #f25138 80%, #f25138 100%);
  color: #fff;
} 

.bg-purple {
  background-image: radial-gradient(ellipse at center, #8a4089 0%, #773576 25%, #6e266d 60%, #6e266d 100%);
  color: #fff;
} 

.bg-magenta {
  background-image: radial-gradient(ellipse at center, #e0775a 0, #d9545b 41%, #d2345c 100%);
  color: #fff;
} 