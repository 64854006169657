// Facebook
$color-facebook: #3B5997;
$border-facebook: #2B4663;
$color-facebook-hover: darken($color-facebook, 5%);

// Twitter
$color-twitter: #2CA9E1;
$border-twitter: #2995BC;
$color-twitter-hover: darken($color-twitter, 5%);

// Google Plus
$color-google-plus: #dd4b39;
$border-google-plus: #C0392B;
$color-google-plus-hover: darken($color-google-plus, 5%);

// Linkedin
$color-linkedin: #0073B4;
$border-linkedin: #055472;
$color-linkedin-hover: darken($color-linkedin, 5%);

// Pinterest
$color-pinterest: #CD1D1F;
$border-pinterest: #8C191E;
$color-pinterest-hover: darken($color-pinterest, 5%);

// E-mail
$color-email: #ddd;
$border-email: #ccc;
$color-email-hover: darken($color-email, 5%);

// WhatsApp
$color-whatsapp: #2ecc71;
$border-whatsapp: #2ecc71;
$color-whatsapp-hover: darken($color-whatsapp, 5%);

// RSS
$color-rss: #FF6501;
$border-rss: #C74803;
$color-rss-hover: darken($color-rss, 5%);

//Flat Colors
$turquoise: #1abc9c;
$greensea: #16a085;
$emerald: #2ecc71;
$nephritis: #27ae60;
$peterriver: #3498db;
$belizehole: #2980b9;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$wetasphalt: #34495e;
$midnightblue: #2c3e50;
$sunflower: #f1c40f;
$orange: #f39c12;
$carrot: #e67e22;
$pumpkin: #d35400;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$concrete: #95a5a6;
$asbestos: #7f8c8d;

//Original Colors
$red: $pomegranate;
$yellow: $sunflower;
$green: $emerald;
$blue: $peterriver;
$orange: $orange;
$purple: $wisteria;
