@mixin max-screen($max){
  @media only screen and (max-width: $max) {
    @content;
  }
}

@mixin min-screen($min){
  @media only screen and (min-width: $min) {
    @content;
  }
}

@mixin custom-device($min, $max){
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}