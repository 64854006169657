@include section(40px);

section{
  width: 100%;
  position: relative;

  &.full{
    min-height: 100vh;
  }
}

