.button.layer{ 
  font-family: $font-secondary;
  line-height: 1.2;

  &:before{
    content: "";
    display: block;
    @include absolute_all();
    background: rgba(#000, 1);
    z-index: -1;
    opacity: 0;
    transition: all 0.4s; 
  }

  &:hover{
    &:before{
      opacity: 1;
    }
  }

  /*----------  Colors  ----------*/
  @each $color-name, $color-code in $btncolors {
    @if (type-of($color-code) == 'color'){
      &.#{$color-name} {
        background: $color-code; 
        border-color: $color-code; 
        box-shadow: darken($color-code, 8%) 4px 4px 0px;
        color: #FFF; 
        fill: #FFF;
  
        &:hover{ 
          background: rgba($color-code, 0.5);
          border-color: $color-code;
          color: #FFF;
          fill: #FFF;
          box-shadow: darken($color-code, 8%) 0px 0px 0px;
        }
      }
    }
  }

  /*----------  Custom  ----------*/
  &.primary{
    color: palette(brand, contrast);
    fill: palette(brand, contrast);
  }
  &.contrast{
    color: palette(brand, primary);
    fill: palette(brand, primary);
  }

  &.white{
    border-color: #fff;
    background: #fff;
    color: palette(brand, primary);
    fill: palette(brand, primary);

    &:hover{
      color: palette(brand, contrast);
      fill: palette(brand, contrast);
    }
  }
}