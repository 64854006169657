/*----------  Shadows  ----------*/

.shadow-1{
	box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 7px!important;
}

.shadow-2{
	box-shadow: rgba(0,0,0,0.15) 0px 9px 30px !important;
}

.shadow-3{
	box-shadow: rgba(0, 0, 0, 0.48) 0px 2px 10px!important;
} 