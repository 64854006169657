.footer {
  position: relative; 
  /* ============= Widgets ============= */
  &__widgets{
    
    position: relative;
    padding: 70px 0 30px;
    background-color: var(--color-background-footer);
    background-image: var(--background-footer-image);
    
    .widget{
      margin-bottom: 40px;
      color: var(--color-widget);
    }

    &.mobile{
      background: #ececec;
      padding: 40px 0 20px;
      color:  var(--color-text);

      .brand-wrap{
        text-align: center;
        background: var(--color-primary);
        color: #FFF;
        margin-top: -40px;
        padding-top: 40px;
      }

      nav.nav{
        border-left: 4px solid var(--color-primary);
        margin: 20px 0;
        padding-left: 20px;
        
        li{
          color: var(--color-text);
        }
      }
    }
    
  }

  .widget-title{
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2em;
    text-align: left;
    margin: 0 0 20px;
  }
  /* ============= Responsivo ============= */
  
  /* ============= Copyright ============= */
  .copyright {
    background-color: var(--color-background-copyright);
    padding: 15px 0;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 2;

    @include linkreset();

    a:hover{
      opacity: 0.7;
    }

    p:last-of-type{
      margin-bottom: 0;
    }
  } // ============= Custom ============= 
  img {
    display: block;
    margin: 0 auto 10px;
    @include max-screen(900px) {
      width: 170px;
    }
  }

  .menu{
    position: relative;
    margin: 0;
    padding: 0 0 0 20px;
    border-left: 1px solid #fff;

    li{
      color: #FFF;
      margin-bottom: 10px;
      font-size: 18px;

      @include linkreset();

      &:hover{
        color: rgba(#fff, 0.5);
      }
    }
  }
}
/* Footer 2 */
.footer_2_hr {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 50px;
}
/* End Fotter 2 */

/* Footer 4 Rodape*/
.footer_4 {
  
 &.footer__widgets{
  padding: 0;
 }
 &.footer__widgets .col-lg-3.col-12, 
 &.footer__widgets .col-lg-2.col-12, 
 &.footer__widgets .col-lg-4.col-12 {
  padding: 40px 25px 40px 25px;
 }
 .bg-verydarkgray {
  background-color: var(--color-background-faixa-footer-4);;
  color: #fff;
 }
 &.footer__widgets{
  padding: 0;
 }
}

/* Rodape Padrão*/
.border-top {
  color: white;
  border-top: 1px solid;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}
.border-top a{
  color: white;
}
/* End Rodape Padrão*/

/* Redes Sociais Rodape*/
.redes-rodape {
  
    span {
      font-weight: 700;
      display: block;
      font-size: 28px;
      line-height: 0.7;
      color:white;
    }

    nav {
      margin-top: 20px;
    }

    div {
    color:white;
    font-size: 20px;
    margin-top:30px;
    }

    hr {
      width: 30%;
      float: left;
      border-width: 4px;
      color:white;
      margin:10px 0 0px;
      padding: 20px 0px;
    }

    a {
      color:white;
    }
}
/* End Redes Sociais Rodape*/