@import "colors",
"spaces",
"shadows",
"dividers",
"images",
"codes",
"responsive/all",
"animations";
.bg-cover {
  @include absolute_all();
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  &~* {
    position: relative;
    z-index: 2;
  }
  &.repeat {
    background-repeat: repeat;
    background-size: initial;
  }
}

.d-flex{display: flex;}

.link-phone {
  text-decoration: none;
  @include linkreset();
}

.next-section {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  &.button {
    border: solid 1px rgba(#fff, 0.28);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(#fff, 0.38);
    color: rgba(#fff, 1);
    padding: 0;
    i {
      font-size: 32px;
      line-height: 0;
    }
    .arrow-down {
      transform: translateY(-30px);
    }
  }
}

.link-absolute {
  @include absolute_all();
  z-index: 5;
  &,
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border: 0;
    text-decoration: none;
    outline: none;
    color: transparent;
    font-size: 0;
    display: block;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.contact-list {
  li {
    border-bottom: 1px solid palette(border);
    padding: 10px 0;
    color: palette(text);
    font-size: 16px;
    @include linkreset();
    a {
      border-bottom: 1px dotted palette(border);
      &:hover,
      &:active {
        opacity: 0.7;
      }

      i{
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    i {
      color: palette(brand, primary);
      font-size: 20px;
    }

    &:last-child{
      border-bottom: 0;
    }
  }
}

/* Prevents img without src to appear */

img:not([src]) {
  visibility: hidden;
}

img[data-src],
img[data-srcset] {
  display: block;
  min-height: 1px;
}

body.not-animate{
  [data-aos]{opacity: 1;transform:none;pointer-events: all;}
}
