.badge{
  position: absolute;
  padding: 5px;
  text-transform: uppercase;
  font-size: 12px;
  color: palette(text, default);
  background-color: palette(grey, ice);
  border-radius: 30px;
  line-height: 1;

  @include m('danger') {
    @extend .badge;
    background-color: palette(stage, danger);
    color: #FFF;
  }



}