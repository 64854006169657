@mixin placeholder {

  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin form_fields {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  input[type="date"],
  input[type="number"], 
  input[type="color"],
  select, textarea{
    @content;
  }
}