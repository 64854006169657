/*  ==========================================================================
  Grid - Layout
  ========================================================================== */
 *{
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}

body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.wrapper{
  position: relative;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  font-size: 16px;
}

main{
  min-height: 100vh;
  position: relative;
  z-index: 2;
}


.row{
  &.small{
    @include gridbs(5px);
  }

  &.medium{
    @include gridbs(20px);
  }

  &.large{
    @include gridbs(28px);
  }

  &.not-gutter{
    @include gridbs(0px);
  }

  .col-auto{
    padding-left: 15px;
    padding-right: 15px;
  }

  &.blocks{
    margin: 0 0;
    @include gridbs(5px);

    > *{
      margin: 5px 0;
    }

    a, img{
      display: block;
    }

    img{
      width: 100%;
    }
  }

  &.split {
    .bg-cover{
      min-width: 100vw;
    }

    .split__left {
      position: relative;
      .bg-cover{
        right: 0;
        left: auto;
      }
    }

    .split__right {
      position: relative;
    }
  }

  // invert Mobile
  @include max-screen(600px) { 
    &.invert-mobile{
      > *{
        order: 1;

        &:first-child{
          order: 2;
        }
      }
    }
  }
  // invert Tablet
  @include custom-device(1023px, 600px) { 
    &.invert-tablet{
      > *{
        order: 1;

        &:first-child{
          order: 2;
        }
      }
    }
  }

  // invert Desktop
  @include min-screen(1024px) { 
    &.invert-desktop {
      > *{
        order: 1;

        &:first-child{
          order: 2;
        }
      }
    }
  }


}//.row