/*
* Name: Header
* Base: .header
* Variações: .fixed, .short
*
*/

.header {
  padding: 5px 0;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  transition: all 0.5s ease-in;
  background-color: #fff;
  @include min-screen(1020px) {
    padding: 20px 0;
  }
  @include max-screen(1020px) {
    position: fixed;
    box-shadow: rgba(#000, 0.3) 0 0 5px;

    @at-root {
      body{
        padding-top: 70px;
      }
    }
  }
  &.on-fixed {
    position: fixed;
    z-index: 20;
    background: var(--color-primary);
    color: #FFF;
    transform: translateY(-110%);
    transition: transform 0.4s, opacity 0.3s;
    opacity: 0;
    will-change: transform, opacity;
    padding: 0;
    box-shadow: rgba(#000, 0.3) 0 0 5px;

    .on-scroll & {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.8s, opacity 0.3s;
      
      .brand{
        opacity: 1;
        transition: all 0.5s 0.2s;
      }
    }
    .brand {
      transition: all 0s;
      max-width: 200px;
      opacity: 0;
      display: flex;
      align-items: center;

      img{
        max-height: 60px;
      }
    }
    .nav {
      >ul>li{
        color: #FFF;
      }
    }

    .button i {
      color: #FFF;
    }

    .button{
      &.primary{
        color: #FFF;
      }

      &.flat{
        color: #fff;
        background: transparent;
        border: #fff solid 2px;
      }
    }
  }
  &__middle {
    padding: 0 1.2rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    >a {
      display: block;
      transition: all 0.5s;
      perspective: 1000px;
      .no-brand & {
        opacity: 0;
        transform: rotateY(180deg);
        transform-style: preserve-3d;
      }
    }
  }
  &__start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__start,
  &__end {
    min-width: 87px;
  }
  img,
  svg {
    display: inline-block;
    transition: all 0.3s cubic-bezier(0.34, 0.46, 1, 1);
    max-height: 33vh;
  }
  &__title {
    font-family: $font-secondary;
    color: #FFF;
    font-size: em(24);
    line-height: 1;
    padding-top: 10px;
  } // ------------- Fixed -------------
  &.fixed {
    position: fixed;
  } 
  /* ------------- mobile ------------- */
  
  @include max-screen(1020px){
    
    &, .button.icon-helper{
      transition: none;
    }
    .on-scroll & {
      background-color: var(--color-primary);

      .button.icon-helper{
        color: #FFF;
      }
    }
  }
  /* ------------- Stiky ------------- */
  .brand{
    img{
      &.show-on-scroll{
        display: none!important;
      }
      &.hide-on-scroll{
        display: block!important;
      }
    }
  }
  .on-scroll & {
    .brand{
      img {
        max-height: 120px;
  
        &.show-on-scroll{
          display: block!important;
        }
        &.hide-on-scroll{
          display: none!important;
        }
      }
    }
  } 

  /* ------------- Short ------------- */
  &.short{
    padding: 10px 0;

    .brand{
      max-width: 230px; 
    }

  }
  /* ------------- Custom ------------- */
  &__end {
    .button.circle {
      color: rgba(#000, 0.2);
    }
  }
  .buton-menu {
    fill: #fff;
    opacity: 0.5;
    width: 55px;
    height: 55px;
  }
  .open-menu {
    position: relative;
    &:before {
      content: "";
      display: block;
      @include absolute_all();
      z-index: 5;
    }
  }
  @include min-screen(1180px) {
    .row .button.icon-helper {
      >* {
        width: 70px;
        height: 70px;
      }
      i {
        font-size: 40px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .brand {
    display: block;
    max-width: 360px;
    padding: 5px 0;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  @include max-screen(1179px) {
    .brand {
      max-width: 200px;
    }
  } // ------------- Responsive -------------
  @include min-screen(1021px) {
    .show-on-mobile {
      display: none !important;
    }
  }
  @include max-screen(1020px) {
    .hide-on-mobile {
      display: none !important;
    }
    .header__middle {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @include max-screen(370px) {
    .row {
      @include gridbs(5px)
    }
  }

  .menu-area {
    display: inline-block;
    vertical-align: middle;
  }

/* ------------- hearder-bottom ------------- */
&.hearder-bottom{
  display: flex;
  text-align: right;

  .col.text-rigth.box1 {
    border-right: 1px solid;
}
  h2.textoMenor {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 0px;
}
  h2.textoMaior {
    margin-top: -26px;
    font-weight: 800;
    font-family: sans-serif;
    margin-bottom: 0px;
    font-size: 21px!important;
}
  .col-auto i {
  line-height: 0.7;
  display: -webkit-inline-box;
  margin-top: 6px;
}
  .col.hide-on-mobile{
    border-top: 1px solid;
    margin-top: 10px;
}
  &.on-fixed > div > div.row {
  display: none;
}
  &.on-fixed .col.hide-on-mobile{
  border-top: 0px solid;
  margin-top: -12px;
}
  &.short.col-auto.header__middle img.hide-on-scroll {
  opacity: 0;
}
  &.on-fixed i.material-icons{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
  .col.hide-on-mobile {
  height: 40px;
}
}
/* ------------- End hearder-bottom ------------- */

}



@import 'top-bar'